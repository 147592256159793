import { Button, DatePicker, Form, Input, Modal, Select, Spin, TimePicker, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscChromeClose } from 'react-icons/vsc';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { AiFillCaretDown } from 'react-icons/ai';
import dateIcon from 'assets/img/icons/date-icon.svg';
import { apiGetUserProject, apiUpdateDailyReport } from 'api/projects';
import { useMutation, useQuery } from 'react-query';
import {
  USE_GET_USER_PROJECT,
  ALL_PROCESS_TYPE,
  MINUTE_STEP,
  FIELD_KEY,
  REPORT_REJECTED,
  NUMBER_LOG_WORK_DAYS_IN_PAST
} from 'constants/Projects';
import { formatDatePiker } from 'helpers/formatDate';
import moment from 'moment';
import { MAX_LENGTH_255 } from 'constants/index';
import { useGetHolidays } from 'hook/useTimeSheet';

const UpdateDailyReport = ({ isOpen, setIsOpen, refetchDaiLyReports, selectedReport }) => {
  const [disable, setDisable] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { data: holidays } = useGetHolidays({});

  useEffect(() => {
    form.setFieldsValue({
      ...selectedReport,
      work_date: moment(selectedReport?.work_date),
      actual_time: moment(moment().startOf('day').add(selectedReport?.actual_time, 'hours')),
    });
  }, [form, selectedReport, isOpen]);

  const { data: projects, isLoading } = useQuery([USE_GET_USER_PROJECT], () => apiGetUserProject(), {
    select: (res) =>
      res?.data?.projects.map((project) => {
        return { value: project.id, label: project.name };
      }),
    onError: (error) => console.log(error),
  });

  const updateDailyReport = useMutation((data) => apiUpdateDailyReport(data.reportId, data.payload), {
    onSuccess: (res) => {
      if (res?.data?.status === 422) message.error(res?.data?.message);
      else {
        message.success(t('project.daily.edit.success'));
        refetchDaiLyReports();
        setIsOpen(!isOpen);
        form.resetFields();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleCancel = () => {
    // form.resetFields();
    setIsOpen(!isOpen);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleSubmit = (values) => {
    setDisable(true);
    const payload = {
      ...values,
      [FIELD_KEY.TASK_ID]: values?.title?.trim(),
      [FIELD_KEY.WORK_DATE]: formatDatePiker(values?.work_date, 'YYYY-MM-DD'),
      [FIELD_KEY.LINK_BACKLOG]: values?.link_backlog?.trim(),
      [FIELD_KEY.DESCRIPTION]: values?.description?.trim(),
      [FIELD_KEY.ACTUAL_TIME]: values?.actual_time.diff(moment().startOf('day'), 'minutes') / 60,
    };

    updateDailyReport.mutate({ reportId: selectedReport?.id, payload: payload });
    setTimeout(() => setDisable(false), 2000);
  };

  return (
    <Modal
      title={t('project.daily.edit.title')}
      centered
      destroyOnClose
      maskClosable={false}
      closeIcon={<VscChromeClose />}
      visible={isOpen}
      onCancel={handleCancel}
      className={styles.modal}
      footer={[
        <Button className="cancelButton" onClick={handleCancel}>
          {t('project.daily.create.cancel')}
        </Button>,
        <Button className="okButton" onClick={handleOk} loading={updateDailyReport.isLoading} disabled={disable}>
          {t('project.daily.create.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className={classNames(styles.formReport, 'materialStyle')}
        size="middle"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        {selectedReport?.reject_reason && selectedReport?.status === REPORT_REJECTED && (
          <div className={styles.rejectReason}>
            <span>{t('project.daily.detail.reasonReject')}</span>
            <p>{selectedReport?.reject_reason}</p>
          </div>
        )}

        <Form.Item
          label={t('project.daily.create.project')}
          required
          name={FIELD_KEY.PROJECT_ID}
          rules={[{ required: true, message: t('project.daily.create.pleaseFillThisField') }]}
        >
          <Select
            showSearch
            showArrow="true"
            allowClear
            showAction={['focus', 'click']}
            suffixIcon={<AiFillCaretDown />}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            dropdownRender={(menu) => menu}
            options={projects}
            notFoundContent={
              isLoading && (
                <div className="text-center">
                  <Spin />
                </div>
              )
            }
          />
        </Form.Item>
        <Form.Item
          label={t('project.daily.create.taskName')}
          required
          name={FIELD_KEY.TASK_ID}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(t('project.daily.create.pleaseFillThisField'));
                } else if (!value.trim() && value) {
                  return Promise.reject(t('project.daily.create.pleaseFillThisField'));
                } else if (value?.length > MAX_LENGTH_255) {
                  return Promise.reject(t('project.daily.create.pleaseFillLessThan', { number: MAX_LENGTH_255 }));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input allowClear maxLength={MAX_LENGTH_255} />
        </Form.Item>

        <div className={styles.formTime}>
          <Form.Item
            className={styles.formWorkDate}
            label={t('project.daily.create.reportDate')}
            required
            name={FIELD_KEY.WORK_DATE}
            rules={[
              { required: true, message: t('project.daily.create.pleaseFillThisField') },
              () => ({
                validator(_, value) {
                  if (moment() < value) {
                    return Promise.reject(t('project.daily.create.pleaseNotReportForFuture'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              placeholder=""
              allowClear
              inputReadOnly
              format="DD/MM/YYYY"
              suffixIcon={<img src={dateIcon} alt="" />}
              disabledDate={(date) => {
                const allowDayReport = moment().subtract(NUMBER_LOG_WORK_DAYS_IN_PAST, 'days').startOf('day');
                const isHoliday = holidays.includes(date.format('YYYY-MM-DD'));
                
                return date && (date <= allowDayReport || date > moment() || isHoliday);
              }}
            />
          </Form.Item>
          <Form.Item
            name={FIELD_KEY.PROCESS_TYPE}
            label={t('project.daily.create.processType')}
            className={styles.formWorkTime}
            required
            rules={[{ required: true, message: t('project.daily.create.pleaseFillThisField') }]}
          >
            <Select
              showSearch
              showArrow="true"
              showAction={['focus', 'click']}
              allowClear
              suffixIcon={<AiFillCaretDown />}
              dropdownRender={(menu) => menu}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={ALL_PROCESS_TYPE.map((item) => ({
                label: t(item.text),
                value: item.key,
              }))}
              placement={'bottomLeft'}
              getPopupContainer={(trigger) => trigger.parentNode}
              className={styles.selectProcessType}
            />
          </Form.Item>
          <Form.Item
            className={styles.formWorkTime}
            label={t('project.daily.create.actualTime')}
            required
            name={FIELD_KEY.ACTUAL_TIME}
            rules={[{ required: true, message: t('project.daily.create.pleaseFillThisField') }]}
          >
            <TimePicker
              autoComplete="off"
              allowClear={false}
              inputReadOnly
              format="HH:mm"
              showNow
              suffixIcon={<AiFillCaretDown />}
              minuteStep={MINUTE_STEP}
              onSelect={(time) => form.setFieldValue(FIELD_KEY.ACTUAL_TIME, time)}
              disabledHours={() => {
                const hours = [];
                // Vô hiệu hóa các giờ lớn hơn 8 giờ
                for (let i = 9; i < 24; i++) {
                  hours.push(i);
                }
                return hours;
              }}
              hideDisabledOptions={true}
            />
          </Form.Item>
        </div>
        <Form.Item
          label={t('project.daily.create.linkBacklog')}
          name={FIELD_KEY.LINK_BACKLOG}
          rules={[
            {
              pattern: 'https://.*',
              message: t('project.daily.create.pleaseFillLinkThisField'),
            },
          ]}
        >
          <Input allowClear type="url" />
        </Form.Item>
        <Form.Item label={t('project.daily.create.note')} name={FIELD_KEY.DESCRIPTION}>
          <TextArea maxLength={1000} showCount autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateDailyReport;
