import 'swiper/swiper.scss';
import React, { useState, useEffect, useContext } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import styles from './../style.module.scss';
import LayoutProfile from './../../../../components/LayoutProfile';
import Loading from 'components/Loading';

import Achievement from './Achievement';
// import Certificates from './Certificates';
import Contract from './Contract';
import Document from './Document';
import Education from './Education';
import Information from './Infomation';
import NavProfile from './../Nav.js';
import Relative from './Relative';
import { ThemContext } from 'layouts/Member';
// import Salary from './Salary';
// import Skill from './Skill';
import { LOADING_MD } from 'constants/index.js';
import { useProfileUser } from 'hook/useProfileEmployee';

SwiperCore.use([Navigation]);

const UserProfile = (props) => {
  const { t } = useTranslation();
  const userId = useContext(ThemContext);

  //Call Api Profile
  const { data: uProfileUser, isLoading: isLoadingProfileUser } = useProfileUser('', userId);

  const [contractNow, setContractNow] = useState({});

  useEffect(() => {
    if (uProfileUser?.data?.contract && uProfileUser?.data?.contract.length) {
      let contractNow = uProfileUser.data.contract.filter((item) => item?.active === 1);
      if (contractNow) {
        setContractNow(contractNow[0]);
      }
    }
  }, [uProfileUser]);

  return (
    <>
      <div className={styles.containerRoot}>
        <div className={styles.rowInfo}>
          {(isLoadingProfileUser || !userId) && <Loading addClass="mt-10" type={LOADING_MD} />}
          {uProfileUser && (
            <>
              <Row>
                <Col md="3">
                  <LayoutProfile uProfileUser={uProfileUser?.data} />
                </Col>
                <Col md="9">
                  <NavProfile />
                  <div className={styles.contentRoot}>
                    <Row>
                      <Col md="12">
                        <Information uProfileUser={uProfileUser?.data} contractNow={contractNow} />

                        {/* hidden Salary */}
                        {/* <Salary uProfileUser={uProfileUser?.data} userId={userId} isLoading={isLoadingProfileUser} /> */}

                        <Relative uProfileUser={uProfileUser?.data} userId={userId} />

                        <Contract uProfileUser={uProfileUser?.data} userId={userId} isLoading={isLoadingProfileUser} />

                        {/* <SkillSheet uProfileUser={uProfileUser?.data} userId={userId} isLoading={isLoadingProfileUser} /> */}

                        <Education uProfileUser={uProfileUser?.data} userId={userId} />

                        <Achievement uProfileUser={uProfileUser?.data} userId={userId} />

                        {/* <Certificates uProfileUser={uProfileUser?.data} userId={userId} /> */}

                        {/* <Skill uProfileUser={uProfileUser?.data} userId={userId} /> */}

                        <Document uProfileUser={uProfileUser?.data} userId={userId} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {userId > 0 && !isLoadingProfileUser && !uProfileUser && (
            <p className={classNames(`${styles.loading} text-center m-0`)}>{t('common.notData')}</p>
          )}
        </div>
      </div>
    </>
  );
};
export default UserProfile;
