import { Button, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { MAX_LENGTH_255 } from 'constants/index';
import dateIcon from 'assets/img/icons/date-icon.svg';
import { IoIosAddCircle } from 'react-icons/io';
import { ALL_PROCESS_TYPE, MINUTE_STEP, FIELD_KEY } from 'constants/Projects';
import classNames from 'classnames';
import { AiFillCaretDown } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import { useGetHolidays } from 'hook/useTimeSheet';
import configs from 'config';

const ReportForm = ({ formName, form, deleteSubFrom }) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [selectedTime, setSelectedTime] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const { data: holidays } = useGetHolidays({});

  const INITIAL_HOUR = 0;

  const onSelectedTime = (time, index) => {
    form.setFieldValue(['group_report', formName, 'report_item', index, FIELD_KEY.ACTUAL_TIME], time);
    setSelectedTime(true);
  };

  useEffect(() => {
    const reportItems = form.getFieldValue(['group_report', formName, 'report_item']).map((item) => {
      return item?.actual_time && item?.actual_time?.diff(moment().startOf('day'), 'minutes') / 60;
    });

    setTotalHours(reportItems.reduce((a, b) => a + b, 0));
    setSelectedTime(false);
  }, [form, formName, selectedTime, deleteSubFrom]);

  return (
    <div>
      <Form.List
        name={[formName, 'report_item']}
        initialValue={[{ work_date: moment(), actual_time: moment().startOf('days').add(INITIAL_HOUR, 'hours') }]}
      >
        {(reports, { add, remove }) => {
          return (
            <div className={styles.reports}>
              {reports.map((report, index) => (
                <div className={styles.reportItem} key={report.key}>
                  <Form.Item
                    // {...report}
                    name={[report.name, `${FIELD_KEY.TASK_ID}`]}
                    label={t('project.daily.create.taskName')}
                    key={`${FIELD_KEY.TASK_ID} -${index}`}
                    className={styles.formRowItem}
                    required
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(t('project.daily.create.pleaseFillThisField'));
                          } else if (!value.trim() && value) {
                            return Promise.reject(t('project.daily.create.pleaseFillThisField'));
                          } else if (value?.length > MAX_LENGTH_255) {
                            return Promise.reject(
                              t('project.daily.create.pleaseFillLessThan', { number: MAX_LENGTH_255 }),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input allowClear maxLength={MAX_LENGTH_255} />
                  </Form.Item>
                  <div className={styles.formRow}>
                    <Form.Item
                      // {...report}
                      name={[report.name, `${FIELD_KEY.PROCESS_TYPE}`]}
                      label={t('project.daily.create.processType')}
                      key={`${FIELD_KEY.PROCESS_TYPE} -${index}`}
                      className={styles.formRowItem}
                      required
                      rules={[{ required: true, message: t('project.daily.create.pleaseFillThisField') }]}
                    >
                      <Select
                        showSearch
                        showArrow="true"
                        showAction={['focus', 'click']}
                        allowClear
                        suffixIcon={<AiFillCaretDown />}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        dropdownRender={(menu) => menu}
                        options={ALL_PROCESS_TYPE.map((item) => ({
                          label: t(item.text),
                          value: item.key,
                        }))}
                        placement={'bottomLeft'}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        className={styles.selectProcessType}
                      />
                    </Form.Item>
                    <Form.Item
                      // {...report}
                      label={t('project.daily.create.linkBacklog')}
                      name={[report.name, FIELD_KEY.LINK_BACKLOG]}
                      key={`${FIELD_KEY.LINK_BACKLOG} -${index}`}
                      rules={[
                        {
                          pattern: 'https://.*',
                          message: t('project.daily.create.pleaseFillLinkThisField'),
                        },
                      ]}
                    >
                      <Input allowClear type="url" />
                    </Form.Item>
                    <Form.Item
                      // {...report}
                      className={styles.formWorkDate}
                      name={[report.name, FIELD_KEY.WORK_DATE]}
                      label={t('project.daily.create.reportDate')}
                      required
                      rules={[
                        { required: true, message: t('project.daily.create.pleaseFillThisField') },
                        () => ({
                          validator(_, value) {
                            if (moment() < value) {
                              return Promise.reject(t('project.daily.create.pleaseNotReportForFuture'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        placeholder=""
                        allowClear
                        inputReadOnly
                        format="DD/MM/YYYY"
                        suffixIcon={<img src={dateIcon} alt="" />}
                        disabledDate={(date) => {
                          const allowDayReport = moment().subtract(configs.NUMBER_LOG_WORK_DAYS_IN_PAST , 'days').startOf('day');
                          const isHoliday = holidays.includes(date.format('YYYY-MM-DD'));
                          
                          return date && (date <= allowDayReport || date > moment() || isHoliday);
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        placement="bottomLeft"
                      />
                    </Form.Item>
                    <Form.Item
                      // {...report}
                      className={styles.formWorkDate}
                      name={[report.name, FIELD_KEY.ACTUAL_TIME]}
                      label={t('project.daily.create.actualTime')}
                      required
                      rules={[{ required: true, message: t('project.daily.create.pleaseFillThisField') }]}
                    >
                      <TimePicker
                        showAction={['focus', 'click']}
                        autoComplete="off"
                        allowClear={false}
                        inputReadOnly
                        format="HH:mm"
                        showNow
                        defaultValue={moment().startOf('day').add(INITIAL_HOUR, 'hours')}
                        suffixIcon={<AiFillCaretDown />}
                        minuteStep={MINUTE_STEP}
                        onSelect={(time) => onSelectedTime(time, index)}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabledHours={() => {
                          const hours = [];
                          // Vô hiệu hóa các giờ lớn hơn 8 giờ
                          for (let i = 9; i < 24; i++) {
                            hours.push(i);
                          }
                          return hours;
                        }}
                        hideDisabledOptions={true}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item label={t('project.daily.create.note')} name={[report.name, FIELD_KEY.DESCRIPTION]}>
                    <TextArea maxLength={1000} autoSize={{ minRows: 3, maxRows: 3 }} />
                  </Form.Item>

                  {reports.length > 1 && (
                    <Button
                      shape="circle"
                      size="small"
                      className={classNames(styles.btnDeleteItem)}
                      icon={<IoClose size={16} color="#fff" />}
                      onClick={() => {
                        remove(report.name);
                        const totalTime = form
                          .getFieldValue(['group_report', formName, 'report_item'])
                          .reduce((a, b) => a + b?.actual_time?.diff(moment().startOf('day'), 'minutes') / 60, 0);
                        setTotalHours(totalTime);
                      }}
                    />
                  )}
                </div>
              ))}

              <div className="d-flex justify-content-between align-items-center">
                <Button
                  type="ghost"
                  className={styles.addReportButton}
                  icon={<IoIosAddCircle size={24} />}
                  onClick={() => {
                    add({ work_date: moment() });
                    const reportItems = form.getFieldValue(['group_report', formName, 'report_item']);
                    reportItems?.map(
                      (item, index) =>
                        !item.actual_time &&
                        form.setFieldValue(
                          ['group_report', formName, 'report_item', index, FIELD_KEY.ACTUAL_TIME],
                          moment().startOf('days').add(INITIAL_HOUR, 'hours'),
                        ),
                    );

                    setTotalHours(totalHours + INITIAL_HOUR);
                  }}
                >
                  {t('project.daily.create.addTask')}
                </Button>
                <span className={styles.totalHours}>
                  {t('project.daily.create.totalHoursReported', {
                    number: totalHours || 0,
                  })}
                </span>
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default ReportForm;
